<!-- Navbar start -->
<section id="header" style="background: #005B41 !important">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="container px-0">
      <nav
        class="navbar navbar-expand-xl"
        style="border-bottom: 2px solid white"
      >
        <a [routerLink]="['']" class="navbar-brand">
          <img
            src="assets/nightlife/logo.png" class="img-fluid rounded-circle"
            style="width: auto; height: 120px"
          />
          <!-- <img src="../proliving/pro_liv.jpeg" class="img-fluid rounded-circle" style="width:4rem; height:4rem;"> -->
        </a>
        <button
          class="navbar-toggler py-2 px-3"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span class="fa fa-bars text-primary"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <div class="navbar-nav d-lg-none">
            <a [routerLink]="['']" class="nav-item nav-link">Home</a>
            <a href="https://unightlifetalk.site/welcome" class="nav-item nav-link"
              >NightLife Talks</a
            >
            <a href="https://nightlifetalks.site/guest" class="nav-item nav-link"
              >NightLife Tweets</a
            >
            <a href="https://umyoappt.site/" class="nav-item nav-link"
              >Booking</a
            >
            <a href="https://umyoevents.site/" class="nav-item nav-link"
              >Events</a
            >
            <a href="https://live.umyoconvo.site/" class="nav-item nav-link"
              >Podcast</a
            >
            <a href="https://uchatyes.com/" class="nav-item nav-link">ChaGPT</a>
            <a href="https://UPMRadio.site/" class="nav-link- nav-item"
            >Radio</a
          >
            <a [routerLink]="['/cf']" class="nav-item nav-link"
              >Card Features</a
            >
            <a [routerLink]="['/package']" class="nav-item nav-link"
              >View Services</a
            >
            <a [routerLink]="['/ai']" class="nav-item nav-link"
              >Additional Features</a
            >
          </div>
          <div class="d-flex m-3 ms-auto">
            <!-- <p class="text-white" style="margin: 2px 25px;    font-size: 25px;">Additional Features</p> -->
            <button
              class="btn-search btn border border-white btn-md-square rounded-circle me-4"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <i class="fas fa-search text-white"></i>
            </button>
            <a href="#" class="position-relative me-4 my-auto">
              <i class="fa fa-shopping-cart text-white fa-2x"></i>
            </a>
            <a href="#" class="my-auto">
              <button class="btn btn-primary bg-white text-dark border-0 p-2">
                Get Started
              </button>
            </a>
          </div>
        </div>
      </nav>
      <nav class="navbar navbar-expand-lg d-lg-block d-none menu-nav2">
        <div class="container-fluid p-0">
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mb-5 pb-3 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['']">Home</a>
              </li>
              <li class="nav-item">
                <a href="https://unightlifetalk.site/welcome" class="nav-link"
                  >NightLife Talks</a
                >
              </li>
              <li class="nav-item">
                <a href="https://nightlifetalks.site/guest" class="nav-link"
                  >NightLife Tweets</a
                >
              </li>
              <li class="nav-item">
                <a href="https://umyoappt.site/" class="nav-link">Booking</a>
              </li>
              <li class="nav-item">
                <a href="https://umyoevents.site/" class="nav-link">Events</a>
              </li>
              <li class="nav-item">
                <a href="https://live.umyoconvo.site/" class="nav-link"
                  >Podcast</a
                >
              </li>
              <li class="nav-item">
                <a href="https://uchatyes.com/" class="nav-link">ChaGPT</a>
              </li>
              <li class="nav-item">
                <a href="https://UPMRadio.site/" class="nav-link"
                  >Radio</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/cf']">Card Features</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/package']"
                  >View Services</a
                >
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/ai']"
                  >Additional Features</a
                >
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
  <!-- Navbar End -->

  <!-- Navbar End -->

  <!-- Modal Search Start -->
  <div
    class="modal fade"
    id="searchModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content rounded-0">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Search by keyword</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body d-flex align-items-center">
          <div class="input-group w-75 mx-auto d-flex">
            <input
              type="search"
              class="form-control p-3"
              placeholder="keywords"
              aria-describedby="search-icon-1"
            />
            <span id="search-icon-1" class="input-group-text p-3"
              ><i class="fa fa-search"></i
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal Search End -->

  <!-- Hero Start -->
</section>
<!------- tab section end ---------->

<section id="acc">
  <div class="accordion-body">
    <div class="accordion">
      <div class="container">
        <h1>
          UMYO Nightlife - Corporate Ambassador Sponsorships - Name - Image -
          Likeness
        </h1>
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-lg-2 col-md-3">
            <h6>Basic Sponsors</h6>
            <p class="text-center">(15k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Custom Sponsors</h6>
            <p class="text-center">(25k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Premium Sponsors</h6>
            <p class="text-center">(35k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Bronze Sponsors</h6>
            <p class="text-center">(50k monthly)</p>
          </div>
          <div class="col-lg-2 col-md-3">
            <h6>Bronze Sponsors</h6>
            <p class="text-center">(50k monthly)</p>
          </div>
          <div class="col-md-1"></div>
          <p class="mt-5 text-center">
            *All Corporate Sponsorships includes ( Double Standing Interactive
            Kiosk/Banner at School of sponsored athlete with Logo” and below
            features
          </p>
          <h4 class="text-center mt-3">
            SEE ADDITIONAL INCENTIVES FOR COMMUNITY COLLABORATION
          </h4>
          <h6 class="text-center mt-3">
            See what’s Included when community brands for you!
          </h6>
        </div>

        <hr />
        <div class="container-content">
          <div class="label">Uchatyes.com</div>
          <div class="content">
            <p>
              What is uChat? uChat is an AI-powered conversational agent based
              on the GPT (Generative Pre-trained Transformer) architecture. It
              can understand and generate human-like responses to text-based
              conversations.
            </p>
            <p class="mx-auto">
              How does uChat work? uChat uses deep learning techniques to
              understand and generate text. It has been trained on a vast amount
              of data and can generate coherent responses based on the input it
              receives.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Standing Kiosk</div>
          <div class="content">
            <p>
              also known as a stand-up kiosk, is an interactive display system
              that is designed for use while standing. These kiosks are
              typically equipped with a touchscreen interface, allowing users to
              interact with the kiosk's software or application to perform
              various tasks. Standing kiosks are commonly found in a variety of
              public spaces and commercial environments, including: education
              Institutions. We will provide standing kiosks for educational
              institutions on behalf of sponsors.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Sports Magazine</div>
          <div class="content">
            <p>
              The new Virtual Audio Video and Image Magazine directories help
              businesses brand logos, ads and virtual business cards to
              educational institutions, organizations, nonprofits and business
              magazines through virtual interactive magazines. Small businesses
              can register to be part of the directory, create their profile and
              add products, goods and services they offer. Virtual magazines are
              available with articles for many types of businesses, including
              finance, family, sports, food, entertainment, business, blogs,
              fashion, social media influencers, salons, attorneys, restaurants,
              retail, article of the month and more
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Coupon Kiosk</div>
          <div class="content">
            <p>
              A virtual version of groupon, only its interactive with video and
              audio ads. Features include the option to share on popular social
              media platforms, use QR codes and share the magazine through email
              and text. Customers can then text the business directly, call or
              link to a business, website or place of contact with the click of
              a mouse.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Commercial</div>
          <div class="content">
            <p>
              UMYO will create a commercial for your business monthly" which
              refers to activities, products, or services that are involved in
              or connected to your entity. We will encompass everything related
              to your business as well as advertisements and promotions, often
              in the form of social media. These are messages created with the
              purpose of promoting and selling a product or service to
              consumers.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">EPK</div>
          <div class="content">
            <p>
              It is our digital interactive online promotional tool used
              primarily for businesses of all types for promotional purposes. We
              will create an EPK collection of digital files and information
              that provide essential details about your business rather that be
              a person, group, company, product, or event, making it easy for
              media professionals, journalists, event organizers, and others to
              quickly access and use this information for publicity and
              reporting.EPKs include high-quality images, logos, and other
              graphics that can be used by media outlets for promotional
              materials or articles.
            </p>
            <p>
              Videos: Video clips, such as trailers, music videos, or
              promotional videos, can be included to give a visual overview of
              the subject.Social Media Links: Links to the subject's social
              media profiles for further engagement and updates.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Google Ads</div>
          <div class="content">
            <p>
              is an online advertising platform developed by Google. It is one
              of the most popular and widely used advertising networks on the
              internet. Google Ads enables businesses and advertisers to display
              their advertisements on Google's search engine results pages
              (SERPs), as well as on other Google properties and a vast network
              of partner websites, which make up the Google Display Network.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">SEO</div>
          <div class="content">
            <p>
              It is a set of practices and strategies aimed at improving a
              website's visibility in search engine results, primarily on search
              engines like Google, Bing, and Yahoo. The ultimate goal of SEO is
              to increase organic (non-paid) traffic to a website by ensuring
              that it ranks higher in search engine results pages (SERPs) for
              relevant and valuable search queries.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">SMS Marketing</div>
          <div class="content">
            <p>
              SMS marketing, also known as text message marketing, is a
              marketing strategy that involves sending short text messages to a
              group of recipients with the goal of promoting products, services,
              events, or offers. This marketing method utilizes SMS (Short
              Message Service) technology, which allows businesses to reach
              customers directly on their mobile phones. SMS marketing can be an
              effective way to engage with customers, deliver time-sensitive
              information, and drive sales.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Unlimited Cards</div>
          <div class="content">
            <p>
              UMYO offers a wide selection of cards for you to choose from. As a
              user, you have the flexibility to create an unlimited number of
              cards by utilizing a variety of template options.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">B2B Branding</div>
          <div class="content">
            <p>
              Commerce or transaction where businesses sell products, services,
              or goods to other businesses rather than selling directly to
              consumers (B2C - Business-to-Consumer). In a B2B context, the
              customers are other companies, and the transactions typically
              involve larger quantities, longer-term contracts, and specialized
              products or services tailored to the needs of the business buyer.
              B2B interactions are common in various industries, including
              manufacturing, distribution, technology, and services.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Email Branding</div>
          <div class="content">
            <p>
              Similar to SMS branding, Email branding refers to the practice of
              customizing and maintaining a consistent and professional visual
              identity for your emails. This visual identity includes elements
              like your company's logo, color scheme, typography, and other
              design elements that represent your brand. Effective email
              branding helps your emails stand out, build trust, and reinforce
              your brand identity with your recipients.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Sponsor an Ambassador</div>
          <div class="content">
            <p>
              refers to a significant change in collegiate athletics in the
              United States. It pertains to the rights of college athletes to
              profit from their own name, image, and likeness. Prior to these
              changes, NCAA (National Collegiate Athletic Association) rules
              prohibited college athletes from earning compensation or benefits
              beyond their scholarships. However, with the introduction of NIL
              rules, college athletes are now allowed to:
            </p>
            <p>
              Endorse Products and Brands: Ambassadors, can enter into
              endorsement deals and promotional contracts with companies and
              brands, allowing them to use their name, image, and likeness for
              marketing and advertising.Monetize Social Media: College athletes
              can profit from their social media platforms by promoting
              products, sharing sponsored content, and leveraging their online
              presence.Sell Autographs and Merchandise: They can sell
              autographs, memorabilia, and merchandise with their names and
              likenesses, benefiting from their personal brand.Participate in
              Camps and Clinics: Athletes can host or participate in sports
              camps and clinics, charging fees for coaching or
              participation.Appear in Video Games and Media: They have the
              opportunity to appear in video games, films, and other media
              projects and earn compensation for their involvement.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">MP3 Branding</div>
          <div class="content">
            <p>
              umyosms.com , similar to constant contact, our MP3 branding"
              refers to the process of adding branding elements, such as
              metadata or audio watermarks, to an MP3 audio file. This is
              commonly done in various contexts, including the music and podcast
              industries, to protect intellectual property, identify the creator
              or source, or enhance the listener's experience.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">
            Virtual Website - The first of its kind virtual reality touch screen
            kiosk website.
          </div>
          <div class="content">
            <p>
              Virtual Reality (VR) Websites: These are websites designed to be
              experienced in virtual reality. Users can navigate and interact
              with the website's content using Virtual interactive experiences.
              VR websites often provide immersive and 3D experiences,
              particularly for gaming, real estate tours, or immersive
              storytelling. Image a touch screen video, audio image website that
              gives
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Marketing Manager</div>
          <div class="content">
            <p>
              A Marketing Manager is a professional responsible for planning,
              executing, and overseeing marketing strategies and activities for
              an organization, business, or product. Their primary objective is
              to promote the brand, attract customers, and drive sales or
              engagement. Developing and implementing marketing strategies and
              plans to achieve the organization's objectives. This includes
              defining target audiences, setting goals, and selecting marketing
              channels.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Campaign Marketing</div>
          <div class="content">
            <p>
              Campaign marketing, also known as marketing campaigns, refers to a
              coordinated series of marketing activities and initiatives with a
              specific goal, message, or objective. These campaigns are designed
              to promote a product, service, event, or cause to a target
              audience. They often have a defined start and end date and are
              structured to achieve specific outcomes.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Commercial/ Contests</div>
          <div class="content">
            <p>
              Why invest thousands of dollars in a commercial that only a
              handful of people will see when you can pay a modest monthly fee
              for the community to generate commercials on your behalf? Now,
              thousands of individuals are casting their votes for the best
              commercials promoting your business, and the winning creators from
              the community receive prizes.have a defined start and end date and
              are structured to achieve specific outcomes.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Social Media Influencer</div>
          <div class="content">
            <p>
              A social media influencer is an individual who has established
              credibility and a dedicated following on one or more social media
              platforms. Influencers have the ability to persuade and impact the
              opinions, behaviors, and purchasing decisions of their followers
              due to their expertise, authenticity, and the trust they've built
              within their niche or industry.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Youth social media branding</div>
          <div class="content">
            <p>
              Youth social media branding refers to the practice of creating and
              managing a distinct and appealing online identity for individuals,
              organizations, or businesses targeting a younger demographic on
              social media platforms. This branding strategy is designed to
              resonate with and engage with young people, who often have unique
              preferences, interests, and behaviors in the digital realm. Also
              using Youth to brand your business and support your business
              through social media. For example: Your Banner at educational
              institutions.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Full Platform Access & Features</div>
          <div class="content">
            <p>
              UMYO Highest tier package- gain full access to umyo’s 16 plus
              websites and features.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Community To Business Branding</div>
          <div class="content">
            <p>
              "Community to Business branding" is a branding strategy that
              focuses on the interaction and relationship between a local or
              online community and a business. It emphasizes building a positive
              and mutually beneficial connection between the two. This approach
              is often associated with small businesses and local enterprises
              that seek to strengthen their ties with the community in which
              they operate"
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Business Network Card</div>
          <div class="content">
            <p>
              “The exclusive business card platform featuring a built-in search
              engine and networking hub that seamlessly connects you with fellow
              community members and entrepreneurs."
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">Accountability Cards</div>
          <div class="content">
            <p>
              "I support the idea of joining an organization dedicated to
              community accountability, reporting violent crimes, and
              contributing $5.99 to $19.99 per month as my way of giving back.
              This allows me to enjoy the numerous platforms offered.
            </p>
          </div>
        </div>
        <hr />
        <div class="container-content">
          <div class="label">FREE Cards</div>
          <div class="content">
            <p>
              Free membership. Limited accessibility and limited features. Free
              members are not eligible to win daily giveaways. Must be an “IAM
              PLEDGE” member to win!!
            </p>
          </div>
        </div>
        <hr />
      </div>
    </div>
  </div>
</section>
<!-- Featurs Section End -->

<!-- Footer Start -->
<div
  class="container-fluid bg-dark text-white-50 footer"
  style="background: #005B41 !important"
>
  <div class="container py-5">
    <div class="row g-4">
      <div class="col-lg-4 col-md-6">
        <h4 class="text-light"><b>Contact us</b></h4>
        <div class="d-flex flex-column text-start footer-item">
          <a class="btn-link" (click)="openPolicyModal()">Privacy Policy</a>
          <a class="btn-link" (click)="openRefundModal()">Refund Policy</a>
          <a class="btn-link" (click)="openTermsModal()">Terms Of Service</a>
          <a class="btn-link" href="">Terms of Sales</a>
          <a class="btn-link" href="">UMYO Nightlife</a>
        </div>
      </div>

      <div class="col-lg-4 col-md-6">
        <div class="footer-item">
          <h4 class="text-light mb-3">LET'S CONNECT</h4>
          <p class="mb-4">
            Subscribe to stay up to date on all the latest Pro-Living features,
            products, stories, and more. You can also contact us at
            contact@MEDICAL.com.
          </p>
          <div class="position-relative mx-auto">
            <input
              class="form-control border-0 py-2 w-100"
              type="text"
              placeholder="Enter Your Email"
            />
            <button
              type="submit"
              class="btn btn-info border-0 border-secondary position-absolute py-2 text-white"
              style="top: 0; right: 0"
            >
              Sent
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="d-flex justify-content-center pt-3">
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-twitter"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-facebook-f"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-instagram"></i
          ></a>
          <a
            class="btn btn-outline-secondary me-2 btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-youtube"></i
          ></a>
          <a
            class="btn btn-outline-secondary btn-md-square rounded-circle"
            href=""
            ><i class="fab fa-linkedin-in"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="col-md-12 text-center text-md-start my-5 mb-md-0">
      <p class="text-white text-center">
        <a href="#" style="text-decoration: none; color: white"
          ><i class="fas fa-copyright text-light me-2"></i>2024 medical</a
        >, All right reserved.
      </p>
    </div>
  </div>
</div>
<!-- Footer End -->

<!-- Featurs Section End -->

<!-- Footer Start -->

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showPolicyModal, 'd-block': showPolicyModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="privacyModalLabel">Privacy Policy</h5>
        <button type="button" class="close" (click)="closePolicyModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-x: hidden;
              overflow-y: scroll;
            "
          >
              Privacy Policy
  
  Last modified: January 10, 2022
  
  Who We Are
  
  Welcome! We are medical Inc., a Texas corporation, or as we prefer to be known, "medical,"
  "we," "our," or "us." Our mission is to revolutionize how the world connects! We provide
  products,services, and technologies ("Products") allowing users ("Users") to organize,
  display, and share information with their contacts through our software, mobile
  application, and website (collectively,"Platform").
  
  We are committed to safeguarding our User’s privacy in accordance with this Privacy Policy
  ("Policy").Please carefully read this Policy to comprehend our policies and practices
  concerning your information and how we handle it. If you disagree with our policies and
  practices, please refrain from using our Platform or purchasing our Products.
  
  How to Contact Us
  
  If you have any questions about our practices or this Policy, please reach out to us via
  email at data-privacy@medical.com or by mail at medical Inc., Attn: Data Privacy
  Officer, 1709 Martin Luther King Blvd Dallas, Texas, 75215
  
  Application of this Policy
  
  Upon accessing our Platform or utilizing our Products, you become a User and agree to
  this Policy, our Data Processing Addendum, and our Terms of Service and Sale
  (collectively, "Policies").
  
  This Policy delineates the types of information we may collect from you or that you may
  provide to us when interacting with our Platform or Products. It further outlines
  our practices for collecting,
  using, maintaining, protecting, and disclosing that information.
  
  Categories of Information We Collect or Receive
  
  Our Platform and Products empower Users to customize their pages and profiles by
  uploading and linking content and information, shareable with other Users or third
  parties. The information provided by a User on the Platform can be extensive. We
  may receive information directly from you or automatically as you access, use, or
  interact with our Platform or Products.
  
  The categories of information we collect or receive include:
  
  - **Identity or Contact Information:** Your first name, last name, or similar
   identifier title, postal addresses, unique personal identifiers, online
  identifiers, IP addresses, email addresses, social media accounts or identifiers
  (e.g., username), age, date of birth, or other similar contact information
  or forms of identification.
  
  - **Transaction Information:** Details about payments to and from you, as well
   as other details of subscriptions and services you have purchased from us.
  
  - **Content Data Information:** Any content you post on the Platform, such as
    pictures, third-party links, documents, writings, preference settings, messages,
    comments, and other contributions, along with metadata about them.
  
  - **Marketing and Communications Information:** Your preferences for receiving
    marketing communications from us and your communication preferences.
    Correspondence content, if any, may be retained.
  
  - **Behavioral and Usage Information:** Details of your access, use, or 
    interaction with our Platform or Products, including traffic data, location
    data, logs, and other communication data.
  
  - **Technical Information:** IP address, browser type and version, time zone
    setting and location, browser plug-in types and versions, operating system
    and platform, and other technology on the devices you use to access our Platform.
  
  - **Aggregated Data or Information:** We may collect, use, and share aggregated 
    data or information such as statistical or demographic data derived from your 
    Personal Information.
  
  Because Users can upload various information on our Platform, we may also receive
  sensitive information, such as your date of birth, racial or ethnic origin, sex,
  political views, affiliations, veteran or military status, religious or philosophical
  views, or similar information. We do not mandate the provision of Sensitive Information,
  but such information may be indirectly collected by us through our hosting of the Platform.
  
  Our Platform also allows you to upload and share information that is not Personal Information
  or Sensitive
  Information, such as your hobbies, pictures, links, descriptions, messages, and comments
  ("Other Information"). As our Platform permits you to choose the Information associated
  with your account, we may link Other Information to Personal or Sensitive Information 
  aboutyou.
  
  For more information on our use of cookies, logs, web beacons, and other tracking
  technologies,refer to the "Third-Party Sources, Cookies, and Other Tracking
  Technologies" section below.
  
  Third-Party Sources, Cookies, and Other Tracking Technologies
  
  We may also collect or receive Personal Information from third-party sources, and the Platform
  may include links to other sites. Any Information you provide on linked pages is submitted
  directly to the respective third-party and is subject to their terms and policies. This 
  Policy does not extend to such linked third-party sites, and we disclaim responsibility 
  for the content, privacy, security practices, and policies of these external sites or any
  other sites linked to or from our Platform.
  
  Certain Information on the Platform may be served by third parties, including advertisers,
  ad networks and servers, content providers, and application providers. These third parties
  may utilize cookies independently or in conjunction with web beacons or other tracking
  technologies to collect Information about you. The Information they gather may be linked
  to your Personal Information or Sensitive Information, or they may collect Information,
  including Personal Information, about your activities across different websites and online
  services.This Information may be used to provide you with interest-based advertising or other
  targeted content. We do not oversee third-party tracking technologies or their usage. For 
  inquiries regarding advertisements or other targeted content, please directly contact the 
  responsible provider.
  
  We may collect Information regarding your Platform usage, encompassing the pages and links
  viewed, time spent on our Platform, demographic data such as server locations, connection
  speed, and other non-identifying Information. Like most online services, we employ cookies
  , log files, clear GIFs, tracking pixels, web beacons, and other technologies that may collect
  Personal Information.
  
  Upon visiting our Platform, one or more "cookies" – small data files – may be sent to your
  device to uniquely identify your browser, enabling us to enhance your navigation experience.
  Cookies may convey Information about your Platform or Products usage to provide you with a 
  more personalized experience. Persistent cookies remain on your device after closing your 
  browser, allowing your browser to use them in subsequent visits. These can be removed by 
  following your web browser's instructions. Session cookies are temporary and vanish after 
  closing your browser.Our Platform may utilize the following types of cookies, logs, web
  beacons, and other tracking 
   technology:
  
  - **Essential Cookies:** Necessary to provide you with services available on our Platform, 
  facilitating actions like logging in to secure areas and expediting Content loading.
  
  - **Functionality Cookies:** Allow us to remember your choices, such as login information, 
    providing a more personalized experience.
  
  - **Analytics and Performance Cookies:** Collect Information about traffic to our Platform 
    and Users' interactions to enhance Platform efficiency and gather aggregated, anonymous
    demographic data.
    Third-party tools and services may be utilized for this purpose.
  
  - **Targeted and Advertising Cookies:** Track browsing habits to show relevant content.
   Information about browsing history may group Users with similar interests, enabling
    third-parties to display ads matching those interests.
  
  - **Social Media Cookies:** Activate when sharing or engaging with Content on social
    media platforms,helping track conversions, optimize ads, and build targeted audiences.
  
  - **Log Files:** Automatically reported by your browser, recording information such
   as web requests, IP addresses, browser types, and pages viewed. Servers automatically
  capture this data whenever you access our Platform.
  
  ### How Can I Disable Cookies?
  
  You can typically configure your web browser to block all cookies or prompt you when
  a cookie is being sent.To do this, follow the instructions provided by your browser,
  usually located within the “settings,” “help,” or “tools” function. Many browsers
  are set to accept cookies by default until you modify your settings.
  
  Should you choose not to accept our cookies, it may result in some inconvenience
  during your use of our Platform, and certain features may not operate correctly.
  For instance, we may be unable to recognize your device, necessitating you to log
  in each time you visit our Platform.
  
  For more details on cookies, including viewing and managing cookies on your device, 
  visit www.allaboutcookies.org or www.whatarecookies.com.
  
  As of now, we do not acknowledge web browsers’ “do not track” signals that offer an
  opt-out method from collecting Information about User activities on the Platform.
  If we adopt this practice in the future, relevant information will be provided in
  this Policy.
  
  ### How We Use Your Information
  
  We utilize the Information collected about you or provided by you, including any
  Personal Information:
  
  - To present our Platform and enhance its contents, providing a personalized
    and improved experience for Users.
  - To furnish information, products, or services requested by you, responding
    to inquiries or facilitating purchases 
    and deliveries.
  - To enable your participation in interactive features, allowing the sharing
    of Personal Information with other Users.
  - To send notices regarding your accounts, pages, or subscriptions, including
    expiry and renewal notifications.
  - To fulfill our contractual obligations and enforce rights, including
    billing and collection.
  - To inform you of changes to our Platform, Products, or services.
  - To uphold the safety, security, and integrity of the Platform, Products,
    databases, and technological assets.
  - For testing, research, analysis, and product development, enhancing and
     evolving our Platform and Products.
  - To respond to law enforcement requests and comply with applicable law,
    court orders, or governmental regulations.
  - In any other manner as described when providing the Information or as
     outlined in applicable law, including the CCPA and GDPR.
  - For any other purpose with your consent.
  
  ### Disclosure of Your Information
  
  We may share your Information, including Personal Information, by disclosing
  it to third parties for the provision of our Platform, its Content, and security,
  or for marketing and selling our Platform or Products. 
  These disclosures occur under written contracts specifying purposes,
  confidentiality requirements, and prohibiting use for any purpose other than contract
   performance. We do not sell your Personal Information.
  
  For Users with an account linked to a “medical for Teams” subscription, the administrator 
  associated with your “medical for Teams” account may manage, access, view, or store your
  Information, including Personal Information. We do not control or limit your account's
  accessibility, and we are not responsible for the administrator's access, disclosure, or
  processing of your Information.
  
  Within the last twelve (12) months, we have disclosed Information, as described in this Policy:
  
  - In connection with your use of our Platform or Products, enabling the sharing of Personal
    Information with other Users.
  - To our service providers, affiliates, agents, subprocessors, advertising partners, 
    internet service providers, data analytics providers, and related third parties, supporting
    Platform and Product functions.
  - To our processors and subprocessors, engaging specific service providers to assist in
     Platform and Product support.
  
  **List of Subprocessors:**
  
  - **Google LLC:** Cloud Service Provider (United States)
  - **Stripe:** Payment Processor (United States)
  - **Hubspot:** Marketing, Sales (CRM), and Support Software Platform (United States)
  - **Chili Piper:** Scheduling Software (United States)
  - **Zapier:** Workflow Integration Software (United States)
  - **Slack:** Messaging and Communication Platform (United States)
  - **Amplitude:** Product and Data Analytics (United States)
  - **Shopify:** Ecommerce Platform (Canada and jurisdictions per Shopify’s policies)
  - **Cloudflare Inc.:** Web Traffic Optimization and Distribution Service (United States)
  - **Covve Visual Network Limited:** Physical business card scanner API (Cyprus)
  - **Rollbar:** Error Monitoring (United States)
  - **Heroku:** Cloud Hosting Provider (United States)
  - **AWS:** Cloud Hosting Provider (United States)
  - **Fivetran:** ETL Tooling (United States)
  - **Looker:** BI (Business Intelligence) tooling (United States)
  - **Cloudinary:** Image hosting API (United States)
  - **Newrelic:** APM (Application Performance Monitoring) (United States)
  - **Passsource:** API (United States)
  - **Klaviyo:** Marketing automation (United States)
  
  **User Data Privacy Policy**
  
  **Effective Date: [Date]**
  
  Welcome to medical! This User Data Privacy Policy ("Policy") outlines how we collect,
  use, disclose, and protect your information when you use our platform, products, and
  services ("Platform" or "Products"). Please read this Policy carefully to understand
  our practices regarding your information and how we will treat it.
  
  ### 1. **How Can I Disable Cookies?**
  
  You have the ability to configure your web browser to block cookies or prompt
  you when a cookie is being sent. Refer to your browser's settings, usually
  located in the "settings," "help," or "tools" function. Please note that
  if you choose to disable our cookies, it may impact your experience on the Platform,
  and certain features may not operate correctly. For more information on cookies,
  including how tomanage them, visit [www.allaboutcookies.org](www.allaboutcookies.org)
  or [www.whatarecookies.com](www.whatarecookies.com).
  
  As of now, we do not acknowledge "do not track" signals from web browsers. If this
  changes in the future, we will provide relevant information in this Policy.
  
  ### 2. **How We Use Your Information**
  
  We use the information collected or provided by you for various purposes, including
  but not limited to:
  
  - Presenting and enhancing the Platform.
  - Providing information, products, or services as requested.
  - Enabling participation in interactive features and sharing information with other Users.
  - Sending notices regarding accounts, pages, or subscriptions.
  - Fulfilling contractual obligations, including billing and collection.
  - Informing you of changes to our Platform, Products, or services.
  - Upholding the safety, security, and integrity of our Platform and Products.
  - Conducting testing, research, analysis, and product development.
  - Responding to law enforcement requests and complying with applicable laws.
  
  ### 3. **Disclosure of Your Information**
  
  We may share your information, including Personal Information, with third parties for various 
  purposes,such as providing our Platform, securing it, and marketing or selling our Products.
  These disclosures are governed by written contracts specifying purposes and confidentiality
  requirements. We do not sell your Personal Information.
  
  **List of Subprocessors:**
  - Google LLC (Cloud Service Provider, United States)
  - Stripe (Payment Processor, United States)
  - Hubspot (Marketing, Sales, and Support Software Platform, United States)
  - Chili Piper (Scheduling Software, United States)
  - Zapier (Workflow Integration Software, United States)
  - Slack (Messaging and Communication Platform, United States)
  - Amplitude (Product and Data Analytics, United States)
  - Shopify (Ecommerce Platform, Canada and jurisdictions per Shopify’s policies)
  - Cloudflare Inc. (Web Traffic Optimization and Distribution Service, United States)
  - Covve Visual Network Limited (Physical business card scanner API, Cyprus)
  - Rollbar (Error Monitoring, United States)
  - Heroku (Cloud Hosting Provider, United States)
  - AWS (Cloud Hosting Provider, United States)
  - Fivetran (ETL Tooling, United States)
  - Looker (BI tooling, United States)
  - Cloudinary (Image hosting API, United States)
  - Newrelic (APM, United States)
  - Passsource (API, United States)
  - Klaviyo (Marketing automation, United States)
  
  ### 4. **Where We Store Your Information**
  
  We maintain our Platform in the United States, and your information may be stored,
  processed, and accessed in the U.S. or elsewhere by us, our employees, contractors,
  and third parties. By using our Platform and providing your information, you consent
  to the export of Personal Information to the United States, understanding that U.S.
  laws may differ from those in your country.
  
  ### 5. **How Long We Store Your Information**
  
  We retain your information for as long as reasonably necessary for the purposes
  outlined in this Policy, unless a longer retention period is required by law. We
  may keep your information for business interests, audits, legal compliance, dispute
  resolution, and agreement enforcement.
  
  ### 6. **Your Rights Related to Your Personal Information**
  
  You may have rights under laws such as the CCPA and GDPR. These rights may include:
  
  - Requesting access to your Personal Information.
  - Right to Know (CCPA) – Receiving information about the collection and use of your
     Personal Information.
  - Requesting correction of your Personal Information.
  - Requesting erasure of your Personal Information.
  - Objecting to processing or requesting restriction of processing.
  - Requesting the transfer of your Personal Information.
  - Withdrawing consent.
  
  ### 7. **How to Exercise Your Rights**
  
  To exercise your rights or inquire about your Personal Information, contact us at 
  [data-privacy@medical.com] (mailto:data-privacy@medical.com) or by mail at
  medical Inc., Attn: Data Privacy Officer, [Address].
  
  We will confirm receipt of your request within ten (10) business days and aim to
  respond within one month, unless the request is complex. If applicable, any disclosures
  will cover the 12-month period preceding the request.
  
  ### 8. **Non-Discrimination**
  
  We do not discriminate against you for exercising your rights. We will not deny goods or
  services, charge different prices, or provide different quality levels based on your
  exercise of privacy rights, unless permitted by law.
  
  ### 9. **How We Protect Your Information**
  
  We implement measures to secure your information from loss and unauthorized access.
  All information is stored on secure servers, and payment transactions are encrypted
  using SSL technology.
  
  ### 10. **Children Under the Age of 16**
  
  Our Platform is not intended for children under 16. We do not knowingly collect
  Personal Information from children under 16. If you are under 16, please do not
  use the Platform or provide any information. If we learn of such information, we
  will delete it promptly.
  
  ### 11. **Opting Out of Sale of Personal Information**
  
  We do not sell Personal Information. If you are 16 or older, you can direct us not
  to sell your information at any time by contacting us at 
  [data-privacy@medical.com](mailto:data-privacy@medical.com) or by mail at
  medical Inc., Attn: Data Privacy Officer, 1709 MLK BLVD Dallas, Texas 75215
  
  **Changes to Our Privacy Policy at medical**
  
  You acknowledge that this Policy is subject to modification by us at any time,
  without prior written notice,and solely at our discretion. Any revisions to this
  Policy will take effect on the "Last Updated Date" or "Last Revised Date"
  indicated on the Platform. Therefore, it is advisable to review our Policies before
  making any purchases or using our Products, Platform, or associated services. Your
  ongoing use of the Platform or any Product following the "Last Updated Date" or "Last
  Revised Date" will signify your acceptance and agreement to these modifications
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showRefundModal, 'd-block': showRefundModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="refundModalLabel">Refund Policy</h5>
        <button type="button" class="close" (click)="closeRefundModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
              **Refund Policy at medical**
  
  **Returns for Defective Items:**
  Our steadfast commitment to delivering high-quality custom items remains unwavering. In the
  event that you receive a defective or damaged item, kindly notify us within 30 days of
  receipt. We will promptly offer a replacement or a full refund for the returned items.
  
  **Return for Customization Issues:**
  If the item received does not align with the agreed-upon customization specifications,
  please inform us within 30 days of receipt. We will collaborate with you to replace
  and reprint it correctly to your satisfaction. In some cases, we may request the return
  of the item to our office to address any issues that occurred during the printing process.
  
  **No Returns for Change of Mind:**
  Due to the personalized nature of our custom items, returns or exchanges stemming from a
  change of mind are not feasible. We encourage you to thoroughly review all customization
  details before confirming your order. This enables us to promptly commence crafting your
  item, ensuring a smooth and efficient process.
  
  **Communication on Specifications:**
  To expedite the process and ensure your custom item exceeds expectations, we rely on
  comprehensive and precise specifications during the ordering process. Your timely
  and detailed communication allows us to swiftly proceed with crafting your custom
  piece, reflecting our commitment to your satisfaction.
  
  If you believe your order requires additional details, please notify us promptly.
  As the fastest custom NFC option on the market, early communication enhances our
  ability to address any potential issues.
  
  **Cancellation Policy:**
  You can cancel your order within 1-2 business days of purchase, provided we haven't
  initiated the creation of your custom item. If cancellation is requested after this
  timeframe, a 10% cancellation fee will be applied to cover the cost of materials and
  work invested in your unique item. This fee will be deducted from your refund.
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngClass]="{ show: showTermsModal, 'd-block': showTermsModal }"
  style="background-color: rgb(0, 0, 0, 0.8)"
>
  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="termsModalLabel">Terms Of Service</h5>
        <button
          type="button"
          class="close"
          (click)="closeTermsModal()"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <pre
            style="
              max-height: 70vh;
              white-space: pre-wrap;
              overflow-y: scroll;
              overflow-x: hidden;
            "
          >
              Welcome to medical!
  
  **Terms of Service and Sale - medical**
  
  **Last Revised: December 22, 2023**
  
  **Terms of Sale and Service**
  
  **Introduction**
  
  Welcome! We are medical, a Delaware corporation, known as "we," "our," or "us." Our mission
  is to redefine how the world connects. We provide Products, Services, and Technologies
  ("Products") that enable Users ("Users") to organize, display, and share information
  with others and third parties ("Services") through our software, mobile application, and
  website (collectively, "Platform").
  
  By submitting an order through the Platform or otherwise ("Order"), you (whether on behalf
  of yourself or an entity you represent) are entering into these Terms of Sale and Service
  ("Terms") with us. You agree to be bound by these Terms and other conditions governing the
  use of our Services, Platform, or the Products offered or sold, such as our Privacy Policy
  and Data Processing Addendum (collectively, "Policies"). If you are under 16, you may not
  use our Platform or Services without parental or guardian permission.
  
  If you disagree with these Terms or our Policies, do not submit an Order or use our
  Platform, Services, or Products.
  
  By agreeing, you confirm that you have the authority to bind yourself and/or the entity
  or company you represent. We may periodically modify these Terms or any Policies at our
  discretion, with the "Last Revised" date indicating such changes.
  
  **Purchase of Products**
  
  (a) When you place an Order, you make an offer to purchase Products according to the
  Order terms, these Terms, and our Policies. An Order is effective upon our acceptance
  or rejection. We may accept or reject Orders at our discretion. Product prices (the
  "Product Purchase Price") are as stated in the Order or, if not in an Order, as
  advertised on our Platform when we accept the Order. Prices exclude taxes; you are
  responsible for such charges.
  
  (b) We select shipment methods and carriers unless specified in the Order. Partial
  shipments are possible at our discretion. You pay for shipped units, including shipping
  and insurance costs unless otherwise stated in the Order.
  
  (c) Products will be delivered to the specified address ("Delivery Point") within a
  reasonable time. Custom Products may have longer processing times. Delivery times are
  estimates, and we are not liable for delays. Title and risk pass upon delivery. If you
  fail to accept delivery, risk passes to you, and we may store the Products at your cost.
  
  (d) Inspect Products within ten (10) days of delivery ("Inspection Period"). Accept or reject
  Nonconforming Products (materially different or defective) during the Inspection Period. If
  notified of Nonconforming Products, ship them to us, and we may replace them.
  
  (e) We warrant Products against significant defects for 1 year ("Warranty Period"). The
  warranty doesn't apply if Products are misused, altered, or used with unauthorized hardware.
  Except for this warranty, we provide no other warranties.
  
  (f) We are not liable for a breach of the warranty unless you give written notice within
  10 days of discovering the defect, allow us to examine the Products, and we verify the
  Nonconforming Products. Remedies for Nonconforming Products are repair, replacement,
  credit, or refund at our discretion.
  
  (g) Your sole remedy for Nonconforming Products is as outlined in Section 2(f).
  
  (h) Changes to Terms in Orders are void unless expressly stated.
  
  **Services**
  
  (a) To use our Services, register and create an account ("Account"). Our Services allow
  Users to create, design, maintain, and share profiles or pages ("Pages"). Subscription
  Levels offer different access levels.
  
  (b) During the Subscription Term, you are granted licensed seats for Services based on
  an accepted Order. Users are responsible for complying with these Terms and our Policies.
  Some Products may require an Account Administrator.
  
  (c) We may establish policies and practices for the Platform, including deleting or
  removing Content, terminating inactive Accounts, or changing Services. Our Services
  may be changed, suspended, or discontinued at any time.
  
  (d) You are responsible for your Account's security. Notify us of any unauthorized
  use promptly. We are not liable for third-party access resulting from your failure
  to comply with these Terms.
  
  **Subscription; Payment Terms**
  
  (a) We offer various Subscription Levels with associated Subscription Fees. Fees
  are subject to change, impacting renewals but not current Subscription Terms.
  
  (b) You agree to pay Subscription Fees associated with your Subscription. Payment
   terms are as stated on our Platform or Order.
  
  (c) We may collect payments through third-party processors. By providing payment
  information, you authorize us to charge your payment method for Fees. Failure to
  verify or accept your payment method may result in suspension or cancellation.
  
  (d) Subscription Fees may consist of recurring charges. By choosing a recurring
  payment plan, you acknowledge and accept responsibility for all recurring charges.
  
  (e) We may submit periodic charges without further authorization until notice of
  termination or payment method change is provided. Charges submitted before notice will
  not be affected.
  
  (f) Your subscription will automatically renew unless terminated before the end of
  the then-current Subscription Term. Termination may occur through Account settings.
  No refunds are provided for early termination.
  
  Certainly! Here's the rewritten version:
  
  ---
  
  **medical App Terms of Service**
  
  **1. Account Information and Updates**
  
  (g) You must provide accurate, current, and complete information for your medical
  account. Ensure timely updates for your billing account, including changes to
  billing address, credit card number, or expiration date. Promptly notify us or
  our payment processor about canceled payment methods or potential security breaches.
  Make changes through your account settings. Failure to provide accurate information
  authorizes us to continue charging subscription fees.
  
  **2. Free Trials and Subscriptions**
  
  (h) Free trials for paid subscription levels are offered with specific terms disclosed
   during the offer. Trials are for users without prior paid subscriptions. Obtaining a
  free tier allows one account creation. Non-genuine use may result in standard fees or
   service denial. Acceptance of free trial subscription implies agreement to associated
    fees upon expiration unless canceled.
  
  **3. User-Generated Content**
  
  (a) Users can post content ("Content") on medical. Posted Content is not confidential,
   treated as public information. By posting, users represent having the right to do so
  and grant us a non-exclusive license to use it for platform operation and promotion.
  
  (b) Users warrant that Content doesn't infringe third-party rights. We reserve the
   right to review and may refuse or remove Content violating terms, policies, or
  laws. Prohibited actions include unlawful, harmful, or objectionable Content;
  collecting personal information without consent; creating derivative works; and
  interfering with platform operations.
  
  (c) The platform may contain links to third-party services ("Third Party
  Services"). These services are not covered by our terms, and we are not
  responsible for them.
  
  (d) Platform content is protected by intellectual property laws. All related
  rights are exclusively ours. Users providing feedback grant us rights to use it freely.
  
  **4. Limitation on Liability**
  
  (a) We're not liable for user-posted Content, user interactions, losses from platform
  use, negative comments, or third-party information provided without consent. Users are
  responsible for interactions with others; we may not intervene in disputes.
  
  (b) We're not liable for indirect, incidental, or exemplary damages arising from a
   breach of terms, regardless of foreseeability. Our aggregate liability is limited
  to the total amounts paid by you in the previous 12 months.
  
  **5. Indemnification**
  
  Users indemnify us against any losses arising from claims by third parties related to
  platform use, purchased products or services, or user negligence or breach of terms.
  
  **6. Governing Law**
  
  These terms are governed by the laws of the State of Alabama.
  
  **7. Binding Arbitration**
  
  (a) By accepting these terms, you waive the right to a jury trial and class action
  participation. Disputes are resolved through binding arbitration. Some claims are
  exempted, including those regarding intellectual property rights or small claims.
  
  (b) Disputes must be addressed informally for at least 30 days before arbitration.
  If unresolved, either party may elect binding arbitration administered by the
  American Arbitration Association in Birmingham, Alabama.
  
  **8. General**
  
  (a) Unenforceability of one provision won't affect others. In case of conflict,
  we decide which provision prevails. Headings are for convenience. Our
  non-enforcement doesn't waive future enforcement rights.
  
  (b) Products and services may be subject to US export control laws. Users
   are responsible for compliance. Communication with us is electronic,
   fulfilling legal requirements.
  
  (c) Notices are provided within the platform or through services. Users
    can notify us via mail.
  
  (d) "Include" or "including" is not limiting, and "or" means "and/or."
  
  ---
  
            </pre
          >
        </div>
      </div>
    </div>
  </div>
</div>
